import { Routes, Route, Link, useParams } from "react-router-dom";
import Main from "./Pages/Main";
import SingleChasingShadows from "./Pages/SingleChasingShadows";
import PreSaveChasingShadows from "./Pages/PreSaveChasingShadows";
import PreSaveChasingShadowsSpotify from "./Pages/PreSaveChasingShadowsSpotify";
import PreSaveSerenitySpotify from "./Pages/PreSaveSerenitySpotify";
import SingleSerenity from "./Pages/SingleSerenity";
import PreSaveCarelessSpotify from "./Pages/PreSaveCarelessSpotify";
import SingleCareless from "./Pages/SingleCareless";
import SingleZero from "./Pages/SingleZero";
import Admin from "./Pages/Admin";
import { useEffect, useState } from "react";
import Login from "./Pages/Login";
import SingleHope from "./Pages/SingleHope";
import Dashboard from "./Pages/Dashboard";
import Loader from "./components/Loader";
import SingleWinter from "./Pages/SingleWinter";

function App() {
  const [loggedUser, setLoggedUser] = useState({ logged: false, key: "" })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const key = getCookie("hls-dsh-key");

    if (key) {
      setLoggedUser({ logged: true, key });
    }
  }, []);

  return (
    <div className="App">

      <Loader loading={loading} />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/chasing-shadows" element={<SingleChasingShadows />} />
        <Route path="/chasing-shadows/success" element={<PreSaveChasingShadows success={true} />} />
        <Route path="/serenity" element={<SingleSerenity />} />
        <Route path="/zero" element={<SingleZero />} />
        <Route path="/hope" element={<SingleHope />} />
        <Route path="/winter" element={<SingleWinter />} />
        <Route path="/chasing-shadows/spotify" element={<PreSaveChasingShadowsSpotify success={false} />} />
        <Route path="/chasing-shadows/spotify/success" element={<PreSaveChasingShadowsSpotify success={true} />} />
        <Route path="/presave/careless/success" element={<PreSaveCarelessSpotify success={true} />} />
        <Route path="/presave/careless" element={<PreSaveCarelessSpotify success={false} />} />
        <Route path="/careless" element={<SingleCareless />} />
        <Route path="/serenity/spotify" element={<PreSaveSerenitySpotify success={false} />} />
        <Route path="/serenity/success" element={<PreSaveSerenitySpotify success={true} />} />
        {loggedUser.logged ? <Route path="/hls/admin/aaa" element={<Admin loggedUser={loggedUser} />} /> : <Route path="/hls/admin/aaa" element={<Login setLoggedUser={setLoggedUser} />} />}
        {loggedUser.logged ? <Route path="/hls/dashboard" element={<Dashboard loggedUser={loggedUser} setLoading={setLoading} />} /> : <Route path="/hls/dashboard" element={<Login setLoggedUser={setLoggedUser} setLoading={setLoading} />} />}
        {/* <Route path="/chasing-shadows" element={<PreSaveChasingShadows success={false} />} /> */}
      </Routes>
    </div>
  );
}

export default App;
