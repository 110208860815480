import { useEffect, useState } from 'react'
import axios from 'axios'
import { getConfig } from '../config/getConfig'
import Spotify from '../images/svg/Spotify'
import Applemusic from '../images/svg/Applemusic'
import Youtube from '../images/svg/Youtube'
import Amazon from '../images/svg/Amazon'
import AmazonMusic from '../images/svg/AmazonMusic'
import Anghami from '../images/svg/Anghami'
import Boomplay from '../images/svg/Boomplay'
import Deezer from '../images/svg/Deezer'
import Itunes from '../images/svg/Itunes'
import Pandora from '../images/svg/Pandora'
import Tidal from '../images/svg/Tidal'
import YoutubeMusic from '../images/svg/YoutubeMusic'
import '../singles.css'
const config = getConfig()

const link = {
    id: 'winter',
    title: 'SEASONS // WINTER - OUT NOW!',
    band_name: 'Her Last Sight',
    song_name: 'SEASONS // WINTER',
    type: 'multi',
    links: {
        spotify: "https://open.spotify.com/album/3QNYeSP6iwPMDlw082FocS",
        applemusic: "https://music.apple.com/us/album/seasons-winter/1768490107",
        youtube: "https://youtube.com/playlist?list=PL9pMLk9-JcsMypGL7r__Go434JOU692pR&si=519CZRtkkK69mDSw",
        deezer: "https://deezer.page.link/uTuJk1fLwr5r2PqW9",
        youtubemusic: 'https://music.youtube.com/playlist?list=OLAK5uy_kG8KB7i3LPWu1PQDvQuTFNZ2FOvBBQePs&si=9YzhSO3fW3fPLOT2',
        itunes: 'https://music.apple.com/us/album/seasons-winter/1768490107?uo=4&app=itunes&at=1001lry3&ct=dashboard',
        tidal: 'https://tidal.com/browse/album/386924220',
        // amazon: 'http://www.amazon.com/gp/product/B0D52JRTNZ/?tag=distrokid06-20',
        // amazonmusic: '',
        // boomplay: '',
        // pandora: '',
        // anghami: '',
    },
    image: "https://res.cloudinary.com/dpwavn5n8/image/upload/v1733997568/ks0sdqcxe93u0hpc3ldu.webp",
    new: true,
}

export default function SingleWinter() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (let [key, value] of urlParams) { paramsObject[key] = value; }

    const queryString = [...urlParams].map(([key, value]) => `${key}=${value}`).join('&');

    async function countVisitors() {
        try {
            axios.get(`${config.backend}/countVisitors?o=${paramsObject.o}&p=winter`)
            axios.get(`${config.backend}/e/view?${queryString}`)
        } catch (error) { }
    }

    async function sendClick(event, data, platform = null) {
        event.preventDefault();

        const dataCopy = JSON.parse(JSON.stringify(data))
        try {
            if (data.type === 'multi') {
                dataCopy.link = data.links[platform]
                delete dataCopy.links
                dataCopy.platform = platform
            }
            const body = { ...paramsObject, clicked_link: encodeURIComponent(dataCopy.link), platform: dataCopy.platform }
            let queryString_click = ""
            Object.keys(body).forEach(key => { queryString_click += key + '=' + body[key] + "&" })
            queryString_click = queryString_click.slice(0, queryString_click.length - 1)
            await axios.get(`${config.backend}/e/click?${queryString_click}`)
        } catch (error) { }
        finally {
            window.location.href = data.links[platform];
        }
    }

    useEffect(() => {
        countVisitors()
    }, [])

    return (<div className='bg_full_single' style={{ backgroundImage: `url(https://res.cloudinary.com/dpwavn5n8/image/upload/v1733997916/jnq6as4ywfzotzhcezqi.webp)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1vh', marginBottom: '3vh' }}>
            <img src="https://res.cloudinary.com/dpwavn5n8/image/upload/v1733998092/ghte7j4ammwazdzos3sy.png" style={{ maxWidth: "50%", height: 'auto' }} alt="" />
            <span className='single_name' style={{ fontSize: '5vw', color: 'black' }}>{link.song_name.toUpperCase()}</span>
        </div>

        {Object.keys(link.links).map(platform => <a className="singleLink_single" onClick={e => { sendClick(e, link, platform) }} href={link.links[platform]}>
            {getPlatformLogo(platform, 45, 45)}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginLeft: '-7%' }}>{getPrettyName(platform)}</div>
            </div>
        </a>)}

        <footer style={{ color: 'black' }}>
            Her Last Sight 2024 ©
        </footer>
    </div>)
}

function getPlatformLogo(platform, width, height) {
    switch (platform) {
        case 'amazonmusic':
            return <AmazonMusic width={width} height={height} />
        case 'amazon':
            return <Amazon width={width} height={height} />
        case 'anghami':
            return <Anghami width={width} height={height} />
        case 'applemusic':
            return <Applemusic width={width} height={height} />
        case 'boomplay':
            return <Boomplay width={width} height={height} />
        case 'deezer':
            return <Deezer width={width} height={height} />
        case 'itunes':
            return <Itunes width={width} height={height} />
        case 'pandora':
            return <Pandora width={width} height={height} />
        case 'spotify':
            return <Spotify width={width} height={height} />
        case 'tidal':
            return <Tidal width={width} height={height} />
        case 'youtubemusic':
            return <YoutubeMusic width={width} height={height} />
        case 'youtube':
            return <Youtube width={width} height={height} />
        default:
            return ""
    }
}
function getPrettyName(platform) {
    switch (platform) {
        case 'amazonmusic':
            return "Amazon Music"
        case 'amazon':
            return "Amazon"
        case 'anghami':
            return "Anghami"
        case 'applemusic':
            return "Apple Music"
        case 'boomplay':
            return "Boomplay"
        case 'deezer':
            return "Deezer"
        case 'itunes':
            return "iTunes"
        case 'pandora':
            return "Pandora"
        case 'spotify':
            return "Spotify"
        case 'tidal':
            return "Tidal"
        case 'youtubemusic':
            return "YouTube Music"
        case 'youtube':
            return "YouTube"
        default:
            return ""
    }
}